<template>
  <div>
    <div class="domain-buttons mb-1">
      <b>Domain Name: {{ domain?.name }}</b>
      <hr>
      <b-button
        v-for="std,index in standards"
        :key="std.id"
        v-b-tooltip.hover="std.name"
        variant="outline-dark"
        class="mb-1 ml-1 me-1"
        :class="{ 'active btn-success': std.id === selectedStandard }"
        @click="handleStandardChange(std)"
      >
        S{{ index }}
      </b-button>
    </div>

    <div class="main-points border dark p-2">
      <h3 class="text-center">
        Load Main Points:
      </h3>
      <div
        v-for="point,index in mainPoints"
        :key="index"
        class="form-check mb-1"
      >
        <b-form-checkbox
          v-model="selectedMainPoints"
          :value="point.value"
          class="form-check-input"
        >
          {{ point.text }}
          <b-button v-if="point.asset"
                    variant="flat-success"
                    class="btn-icon"
                    @click="mediaAsset = point.asset"
          >
            <feather-icon icon="FileTextIcon" />
          </b-button>
        </b-form-checkbox>
      </div>
    </div>
    <MediaPreview v-if="!!mediaAsset"
                  :asset="mediaAsset"
                  @close="mediaAsset = null"
    />
  </div>
</template>
<script setup>
import axios from 'axios'
import { BButton, BFormCheckbox } from 'bootstrap-vue'
import { ref, onMounted, watch } from 'vue'
import MediaPreview from './MediaPreview.vue'

const standardEndPoint = '/api/v1/ai-demo/get-standards'

const props = defineProps({
  domain: {
    type: Object,
    default: () => null,
  },
})

const mediaAsset = ref(null)
const selectedMainPoints = ref([])
const selectedStandard = ref([])
const standards = ref([])
const emits = defineEmits(['onMainPointChange'])

// const mainPoints = computed(() => standards.value.find(i => i.id === selectedStandard.value)?.main_points || {})
const mainPoints = ref([])

const getStandards = () => {
  if (!props.domain || !props.domain.id) {
    console.error('Domain is not defined or missing an id:', props.domain)
    return
  }

  axios.post(`${standardEndPoint}/${props.domain.id}`).then(response => {
    standards.value = response.data
  })
}

const handleStandardChange = std => {
  selectedStandard.value = std.id
  const mainPointObj = standards.value.find(i => i.id === std.id)?.main_points || {}
  console.log(mainPointObj)
  // mainPoints.value = Object.values(standards.value.find(i => i.id === std.id)?.main_points || {}).map(i => ({ text: i, value: i }))
  mainPoints.value = [
    {
      text: mainPointObj.point_1,
      value: mainPointObj.point_1,
      asset: mainPointObj.assets_url_1 || null,
    },
    {
      text: mainPointObj.point_2,
      value: mainPointObj.point_2,
      asset: mainPointObj.assets_url_2 || null,
    },
    {
      text: mainPointObj.point_3,
      value: mainPointObj.point_3,
      asset: mainPointObj.assets_url_3 || null,
    },
  ]
  selectedMainPoints.value = mainPoints.value.map(i => i.value)
}

watch(selectedMainPoints, newValue => {
  emits('onMainPointChange', newValue)
})

onMounted(() => {
  getStandards()
})

</script>
