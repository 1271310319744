<template>
  <section class="chat-app-window"
           style="position: relative"
  >
    <div class="active-chat">
      <div class="chat-navbar">
        <header class="chat-header">
          <div
            class="d-flex justify-content-between w-100 align-items-center"
          >
            <div class="d-flex align-items-center">
              <div class="sidebar-toggle d-block d-lg-none mr-1">
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  width="21px"
                  height="21px"
                  viewBox="0 0 24 24"
                  fill="none"
                  stroke="currentColor"
                  stroke-width="2"
                  stroke-linecap="round"
                  stroke-linejoin="round"
                  class="cursor-pointer feather feather-menu"
                >
                  <line x1="3"
                        y1="12"
                        x2="21"
                        y2="12"
                  />
                  <line x1="3"
                        y1="6"
                        x2="21"
                        y2="6"
                  />
                  <line x1="3"
                        y1="18"
                        x2="21"
                        y2="18"
                  />
                </svg>
              </div>
              <b-avatar
                :src="avatar.botImg"
                class="mr-1 avatar-border-2 box-shadow-1"
                size="36"
                variant="transparent"
              />
              <h6 class="mb-0">
                {{ $t("ai-ide-module.chat-bot") }}
              </h6>
            </div>
          </div>
        </header>
      </div>
      <vue-perfect-scrollbar
        ref="refChatLogPS"
        :settings="perfectScrollbarSettings"
        class="ai-chats scroll-area user-chats"
      >
        <div class="chats">
          <div
            v-for="(msg, index) of props.messages"
            :key="index"
            class="chat"
            :class="msg.role === 'system' && 'chat-left bot-response'"
          >
            <div class="chat-avatar">
              <span
                class="b-avatar avatar-border-2 box-shadow-1 badge-transparent rounded-circle"
                style="width: 36px; height: 36px"
              ><span class="b-avatar-img"><img
                :src="
                  msg.role === 'system' ? avatar.botImg : avatar.avatar
                "
                alt="avatar"
              ></span>
              </span>
            </div>
            <div :class="[ msg.role === 'system' ? 'pl-4': 'chat-body']">
              <div class="chat-content">
                <p v-html=" msg.content?.replaceAll('\n', '<br />')" />
              </div>
            </div>
          </div>
          <div v-if="isBotTyping"
               class="chat chat-left"
          >
            <Preloader class="pl-2" />
          </div>
        </div>
      </vue-perfect-scrollbar>

      <form class="chat-app-form">
        <div class="d-flex w-100">
          <div
            role="group"
            class="input-group input-group-merge form-send-message mr-1"
          >
            <span class="w-100">
              <textarea
                v-model="typedMessage"
                rows="1"
                max-rows="8"
                tag="pre"
                :placeholder="$t('chat-page.enter-your-message')"
                class="form-control"
                style="resize: none"
                @keyup.enter="onEnter"
              />
            </span>
          </div>
          <button
            type="button"
            class="btn btn-primary"
            :disabled="isBotTyping || !typedMessage"
            @click="sendMessage"
          >
            {{ $t("send-notification-module.send") }}
          </button>
        </div>
      </form>
    </div>
  </section>
</template>
<script setup>
import { ref } from 'vue'
import { BAvatar } from 'bootstrap-vue'
import VuePerfectScrollbar from 'vue-perfect-scrollbar'
import Preloader from '@/views/common/components/Preloader.vue'

const perfectScrollbarSettings = {
  maxScrollbarLength: 150,
}
const props = defineProps({
  messages: {
    type: Array,
    default: () => [],
  },
  isBotTyping: {
    type: Boolean,
    default: false,
  },
})

const userInfo = {
  firstname: 'Student',
  lastname: '',
  avatar: '/img/new-logo.5e5f5955.svg',
}
const avatar = {
  fullName: userInfo.firstname + userInfo.lastname,
  avatar: userInfo.avatar,
  botImg: '/img/new-logo.5e5f5955.svg',
}
const typedMessage = ref('')
const emits = defineEmits(['onMessage'])

const sendMessage = () => {
  emits('onMessage', typedMessage.value)
  typedMessage.value = ''
}

const onEnter = event => {
  if (event.shiftKey || props.isBotTyping) {
    // Insert a new line
    typedMessage.value += '\n'
  } else if (typedMessage.value?.trim()) {
    sendMessage()
  }
}
</script>
<style>
.ai-chats {
  height: calc(100vh - 350px) !important;
}
.preloader {
  position: absolute;
  bottom: 0;
  margin-bottom: 20px;
}
.user-chats {
  background-image: none !important;
}
.bot-response {
  width: 100%;
}
</style>
