<template>
  <div class="filters">
    <b-form-group label="Select Grade"
                  label-for="gradeDropdown"
    >
      <b-form-select
        v-model="selectionForm.grade"
        :options="grades"
        placeholder="Select Grade"
        @change="getSubject"
      />
    </b-form-group>

    <b-form-group label="Select Subject"
                  label-for="gradeDropdown"
    >
      <b-form-select
        v-model="selectionForm.subject"
        placeholder="Select subjects"
        :options="subjects.map((sub) => ({ value: sub.id, text: sub.name }))"
      />
    </b-form-group>

    <div class="domain-buttons mb-1">
      <b-button
        v-for="domain in domains"
        :key="domain.id"
        v-b-tooltip.hover="domain.name"
        variant="outline-dark"
        class="mb-1 ml-1 me-1"
        :class="{ 'active btn-success': domain.id === selectionForm.domain }"
        @click="selectDomain(domain)"
      >
        {{ domainNumber(domain.code) }}
      </b-button>
    </div>
  </div>
</template>
<script setup>
import axios from 'axios'
import { BFormGroup, BFormSelect, BButton } from 'bootstrap-vue'
import {
  ref, onMounted, reactive, computed,
} from 'vue'

const subjectEndPoint = '/api/v1/ai-demo/get-subjects'

const emits = defineEmits(['onDomainChange'])
const DEFAULT_GRADE = process.env.VUE_APP_REA_DEFAULT_SUBJECT ?? 49

const selectionForm = reactive({
  grade: DEFAULT_GRADE,
  subject: null,
  domain: null,
})

const grades = [
  {
    value: DEFAULT_GRADE,
    text: 'Advanced Placement',
  },
  {
    value: 50,
    text: 'Foundational Knowledge',
  },
  {
    value: 51,
    text: 'Elementary Topics',
  },
]

const subjects = ref([
  {
    value: null,
    name: 'Select Subjects',
  },
])

const domains = computed(
  () => subjects.value.find(sub => sub.id === selectionForm.subject)?.domains
    || [],
)

const domainNumber = computed(() => code => {
  // Match the number after the last two dots
  const match = code.match(/(\d+\.\d+(\.\d+)?)$/)
  return match ? match[0] : code // Remove the first dot
})

const getSubject = () => {
  axios.post(`${subjectEndPoint}/${selectionForm.grade}`).then(response => {
    subjects.value = response.data
    selectionForm.subject = response.data[0]?.id || null
  })
}

const selectDomain = domain => {
  selectionForm.domain = domain.id
  emits('onDomainChange', domain)
}

onMounted(() => {
  getSubject()
})
</script>
